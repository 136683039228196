@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.sectionTitleArea {
  color: $black700;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 20px;

  img {
    width: 50px;
    margin-right: 5px;
  }

  .titlePurple {
    color: $primary500;
  }
}

.labelTitleContainer {
  margin: 0 auto 30px;
  max-width: 100%;
  padding: 20px;
  display: flex;
  gap: 26px;
  overflow-x: auto;
  width: fit-content;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.labelTitle {
  flex-shrink: 0;
  display: grid;
  place-content: center;
  border-radius: 30px;
  background-color: $primary500;
  padding: 15.5px 42px;

  font-weight: 700;
  font-size: 18px;
  line-height: 1.6;
  color: #fff;


  cursor: pointer;
}

.labeledContentWrapper {
  display: flex;
  justify-content: center;

  .list {
    @include gridList(50px, 30px) {
      margin-bottom: 30px;
  
      @media(max-width: 1310px) {
        column-gap: 50px;
      }
  
      @include sm {
        column-gap: 30px;
      }
  
      @include tn {
        row-gap: 40px;
        column-gap: 10px;
      }
    };
  
  }
}

