@use '../../utils/constants.scss' as *;

.hyperlink {
  color: $primary500;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: lighten($color:  $primary500, $amount: 10%);
    text-decoration: underline;
  }
}